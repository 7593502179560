import axios from "axios";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Modal } from "react-morphing-modal";
import "react-morphing-modal/dist/ReactMorphingModal.css";
import Biver from "../assets/icons/Biver.svg";
import Button from "../components/Button";
import { Input, Select } from "./FormComponents";

function TestModal({ modalProps }) {
  const { register, handleSubmit, errors } = useForm();

  const [loading, setLoading] = useState(false);
  const [feedback, setFeedback] = useState(false);

  const onSubmit = (data, e) => {
    setLoading(true);
    setFeedback(null);

    axios
      .post("https://biver-back.now.sh/subscribe", {
        email: data.email,
        telefone: data.telefone,
        empresa: data.empresa,
        cnpj: data.cnpj,
        ramo: data.ramo,
        tamanho: data.tamanho,
      })
      .then((response) => {
        if (response.data.success) {
          setLoading(false);
          setFeedback({
            message: response.data.message,
            type: "success",
          });
        } else {
          setLoading(false);
          setFeedback({
            message: response.data.message,
            type: "warning",
          });
        }
        e.target.reset();
      })
      .catch(() => {
        setLoading(false);
        setFeedback({
          message:
            "Não foi possível realizar esta operação, tente novamente mais tarde.",
          type: "err",
        });
      });
  };

  const ramos = [
    "Agência criativa",
    "Comércio (atacado e varejo)",
    "Construção",
    "Consultoria",
    "Desenvolvimento de software",
    "Educação e formação",
    "Fabricação",
    "Franquia",
    "Imobiliária",
    "Notícias e imprensa",
    "Outros",
    "Saúde",
    "Serviços de TI",
    "Serviços financeiros ou de crédito",
    "Start-up de tecnologia",
  ];

  const tamanhos = [
    "Até 5 funcionários",
    "6 a 15 funcionários",
    "16 a 30 funcionários",
    "31 a 50 funcionários",
    "Mais de 50 funcionários",
  ];

  return (
    <Modal {...modalProps}>
      <div className="text-center p-5 xl:px-56 xl:py-10">
        <img src={Biver} className="w-3/4 md:w-1/3 lg:w-1/4 m-auto" alt="" />
        <h3 className="font-bold mt-10 mb-4 lg:mx-64">
          Preencha os campos abaixo para entrar em contato com nossa equipe
          comercial.
        </h3>

        <form onSubmit={handleSubmit(onSubmit)} className="w-full lg:w-7/12">
          <Input
            required
            label="E-mail"
            name="email"
            type="email"
            register={register({ required: true })}
            errors={errors}
          />

          <Input
            required
            label="Telefone"
            name="telefone"
            mask="(99) 999999999"
            register={register({ required: true })}
            errors={errors}
          />

          <Input
            required
            label="Nome da empresa"
            name="empresa"
            register={register({ required: true })}
            errors={errors}
          />

          <Input
            label="CNPJ"
            name="cnpj"
            mask="99.999.999/9999-99"
            register={register}
            errors={errors}
          />

          <div className="md:flex">
            <Select
              label="Ramo de atividade"
              name="ramo"
              register={register}
              options={ramos}
              className="md:mr-4 md:w-1/2"
            />
            <Select
              label="Tamanho da empresa"
              name="tamanho"
              options={tamanhos}
              register={register}
              className="md:w-1/2"
            />
          </div>

          <Button className="mt-8 w-full" type="submit">
            {loading ? "Enviando..." : "Entrar em contato"}
          </Button>

          {feedback ? (
            <div className={`alert-${feedback.type} mt-4 p-2`}>
              <p className="text-black">{feedback.message}</p>
            </div>
          ) : null}
        </form>

        <p className="mt-6 text-gray text-center lg:mx-48 hidden">
          Ao solicitar a demonstração gratuita, você concorda com as{" "}
          <span className="text-salmon">Políticas de privacidade</span>,{" "}
          <span className="text-salmon">Termos de uso</span> e uso de Cookies.
        </p>
      </div>
    </Modal>
  );
}

TestModal.propTypes = {
  modalProps: PropTypes.any,
};

export default TestModal;
