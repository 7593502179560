import { Link } from "gatsby";
import React, { useState } from "react";
import Biver from "../assets/icons/Biver.svg";
import Button from "../components/Button";
import ButtonTestModal from "../components/ButtonTestModal";

function PageHeader() {
  const [isExpanded, toggleExpansion] = useState(false);

  const routes = [
    {
      route: `/recursos`,
      title: `Recursos`,
    },
    {
      route: `/planos`,
      // title: `Planos e preços`
      title: `Planos`,
    },
    {
      route: `/biver`,
      title: `Por que Biver?`,
    },
    {
      route: `/contato`,
      title: `Contato`,
    },
    {
      route: `/login`,
      title: `Entrar`,
    },
  ];

  return (
    <header className="p-5 px-8 flex flex-wrap items-center background-white">
      <Link className="w-1/2 lg:w-1/6 xl:w-1/4" to="/">
        <img src={Biver} className="md:w-2/4 lg:w-11/12 xl:w-2/4" alt="" />
      </Link>

      <button
        className="block lg:hidden ml-auto px-3 py-2 text-white"
        onClick={() => toggleExpansion(!isExpanded)}
      >
        <box-icon name="menu" color="#e57668">
          <title>Menu</title>
        </box-icon>
      </button>

      <nav
        className={`${
          isExpanded ? `block` : `hidden`
        } lg:block lg:flex w-full lg:justify-between lg:w-2/4 xl:w-2/4`}
      >
        {routes.map((link) =>
          link.title !== "Entrar" ? (
            <Link
              className="navbar text-blue block lg:inline-block mt-4 lg:mt-0 lg:ml-6 no-underline"
              key={link.title}
              to={link.route}
            >
              {link.title}
            </Link>
          ) : (
            <a
              href="https://app.gravittem.com/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <div className="block text-blue mt-4 lg:hidden">Login</div>
            </a>
          )
        )}
      </nav>

      <div className="hidden lg:block lg:w-2/6 xl:w-1/4 text-right ml-auto">
        <a
          href="https://app.gravittem.com/"
          rel="noopener noreferrer"
          target="_blank"
        >
          <Button ghost>Login</Button>
        </a>
        <ButtonTestModal className="ml-5">ENTRAR EM CONTATO</ButtonTestModal>
      </div>
    </header>
  );
}

export default PageHeader;
